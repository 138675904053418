import { createSelector } from '@reduxjs/toolkit';
import { edgeApi } from '../services/edgeApi';

// https://redux.js.org/tutorials/essentials/part-8-rtk-query-advanced#selecting-users-data
// export const selectMapResult = edgeApi.endpoints.getMapData.select();
export const selectMapPointsResult = edgeApi.endpoints.getMapPoints.select();
export const selectMapNodesResult = edgeApi.endpoints.getMapNodes.select();
export const selectMapEdgesResult = edgeApi.endpoints.getMapEdges.select();
export const selectTspsResult = edgeApi.endpoints.getTSPs.select();
export const selectInterconnectsResult =
  edgeApi.endpoints.getInterconnects.select();

const emptyObject = {};

/**
 *
 * @param {import('./plotterSlice').PlotterState} state
 * @returns {string[] | undefined}
 */
const getFilteredTsps = (state) => state.filters.pointAttributes?.tsp_name;

// export const selectApiNodes = createSelector(
//   selectMapResult,
//   (mapResult) => mapResult ?? emptyNodes
// )

export const selectApiNodes = createSelector(
  selectMapNodesResult,
  (mapResult) => {
    if (mapResult?.data) {
      if (
        localStorage.getItem('noCache') !== 'true' &&
        mapResult.fulfilledTimeStamp >
          Number(localStorage.getItem('apiNodesTimeStamp'))
      ) {
        console.info('caching nodes to local storage');
        try {
          localStorage.setItem('apiNodes', JSON.stringify(mapResult.data));
          localStorage.setItem(
            'apiNodesTimeStamp',
            mapResult.fulfilledTimeStamp,
          );
        } catch (e) {
          console.info('caching nodes failed');
        }
      }
      return mapResult.data;
    } else {
      return JSON.parse(localStorage.getItem('apiNodes') ?? '{}');
    }
  },
);

export const selectApiPoints = createSelector(
  selectMapPointsResult,
  (mapResult) => {
    if (mapResult?.data) {
      if (
        localStorage.getItem('noCache') !== 'true' &&
        mapResult.fulfilledTimeStamp >
          Number(localStorage.getItem('apiPointsTimeStamp'))
      ) {
        console.info('caching points to local storage');
        /**
         * This is a workaround to reduce the number of points that are saved to local storage
         * local storage has a limit of 5MB, and the points object can be quite large
         * this will only cache points that are useful for us right at the beginning of the session.
         *
         * Ideally we would have a way to save all the points by reducing the size of their representation
         * eg: using binary data, having less fields to each point, etc...
         * We could also use something other than local storage to do this, but that would require a lot of work.
         * This works fine for now.
         */
        const mappedPoints = Object.keys(mapResult.data).reduce((acc, key) => {
          const point = mapResult.data[key];
          if (point.node !== null && point.node !== undefined) {
            acc[key] = mapResult.data[key];
          }
          return acc;
        }, {});
        try {
          localStorage.setItem('apiPoints', JSON.stringify(mappedPoints));
          localStorage.setItem(
            'apiPointsTimeStamp',
            mapResult.fulfilledTimeStamp,
          );
        } catch (e) {
          console.info('caching points failed');
        }
      }
      return mapResult.data;
    } else {
      return JSON.parse(localStorage.getItem('apiPoints') ?? '{}');
    }
  },
);

export const selectApiEdges = createSelector(
  selectMapEdgesResult,
  (mapResult) => {
    if (mapResult?.data) {
      if (
        localStorage.getItem('noCache') !== 'true' &&
        mapResult.fulfilledTimeStamp >
          Number(localStorage.getItem('apiEdgesTimeStamp'))
      ) {
        console.info('caching edges to local storage');
        try {
          localStorage.setItem('apiEdges', JSON.stringify(mapResult.data));
          localStorage.setItem(
            'apiEdgesTimeStamp',
            mapResult.fulfilledTimeStamp,
          );
        } catch (e) {
          console.info('caching edges failed');
        }
      }
      return mapResult.data;
    } else {
      return JSON.parse(localStorage.getItem('apiEdges') ?? '{}');
    }
  },
);

export const selectInterconnects = createSelector(
  selectInterconnectsResult,
  /**
   *
   * @param {{data: Object<string, import('../services/edgeApi').Interconnect>}} interconnectsResult
   * @returns
   */
  (interconnectsResult) => interconnectsResult?.data ?? emptyObject,
);

export const selectInterconnectsAtPoints = createSelector(
  selectInterconnects,
  (interconnects) =>
    Object.values(interconnects).reduce(
      /**
       * @param {{[key: string]: import('../services/edgeApi').Interconnect[]}} acc
       * @param {import('../services/edgeApi').Interconnect} interconnect
       *
       */
      (acc, interconnect) => {
        if (!acc[interconnect.start_point]) {
          acc[interconnect.start_point] = [];
        }
        if (!acc[interconnect.end_point]) {
          acc[interconnect.end_point] = [];
        }
        acc[interconnect.start_point].push(interconnect);
        acc[interconnect.end_point].push(interconnect);
        return acc;
      },
      {},
    ),
);

export const selectTsps = createSelector(
  selectTspsResult,
  (tspsResult) => tspsResult?.data ?? emptyObject,
);

// export const selectApiNodes = (state) => selectMapResult?.data?.nodes ?? {}
// export const selectApiPoints = (state) => selectMapResult?.data?.points ?? emptyNodes

// export const selectAllNodes = (state) => {
//   // combine api nodes and localNode - localNode takes precedence
//   return {...selectApiNodes, ...state.plotter.localNode}
// }

export const selectLocalNode = createSelector(
  // keep getting localNode from state, the lat / lng and other points will be saved to it.
  // Then programmatically add the selected point to the node in this selector
  (state) => state.plotter.localNode,
  (state) => state.selectedItems.editPointId[0],
  (localNode, editPointId) => {
    // if there's no localNode, don't return anything
    // if(Object.keys(localNode).length === 0){
    if (!localNode.id) {
      return {};
    }
    let updatedNodePoints = localNode.points;
    // this is going to add the selected point to every localNode so might need to clear selectedPoint
    if (editPointId) {
      updatedNodePoints = localNode.points.filter(
        (nodePoint) => nodePoint != editPointId,
      );
      updatedNodePoints = updatedNodePoints.concat(editPointId);
    }
    if (updatedNodePoints.length === 0) {
      // add 'new' point to it for creating new point in map builder?
      // or is this something we want to handle in the map builder components if there are no points so it stays map builder specific - probably
      updatedNodePoints = updatedNodePoints.concat('new');
    }
    return {
      // ...existinglocalNode,
      [localNode.id]: {
        ...localNode,
        points: updatedNodePoints,
      },
    };
  },
);

export const selectAllNodes = createSelector(
  selectApiNodes,
  // (state) => state.plotter.localNode,
  selectLocalNode,
  /**
   *
   * @param {{[key: string]: import('../services/edgeApi').Node}} apiNodes
   * @param {{[key: string]: import('../services/edgeApi').Node}} localNode
   * @returns {{[key: string]: import('../services/edgeApi').Node}}
   */
  (apiNodes, localNode) => ({ ...apiNodes, ...localNode }),
);

export const selectAllPoints = createSelector(
  selectApiPoints,
  (state) => state.plotter.localPoints,
  /**
   *
   * @param {{[key: string]: import('../services/edgeApi').Point}} apiPoints
   * @param {{[key: string]: import('../services/edgeApi').Point}} localPoints
   * @returns {{[key: string]: import('../services/edgeApi').Point}}
   */
  (apiPoints, localPoints) => {
    // want to show whether it's local (not saved yet) or from api (already saved)
    // is this the best way?  Or does it add a lot of processing?
    // console.log(localPoints)
    const updatedLocalPoints = {};
    Object.keys(localPoints).map((localPointId) => {
      updatedLocalPoints[localPointId] = {
        ...localPoints[localPointId],
        isLocal: true,
      };
    });
    // console.log(updatedLocalPoints)
    return { ...apiPoints, ...updatedLocalPoints };
  },
);

const useConditions = (search) => (a) =>
  Object.keys(search).every(
    (k) =>
      a[k] === search[k] ||
      (Array.isArray(search[k]) &&
        (search[k].length === 0 || search[k].includes(a[k]))) ||
      (typeof search[k] === 'object' &&
        +search[k].min <= a[k] &&
        a[k] <= +search[k].max) ||
      (typeof search[k] === 'function' && search[k](a[k])) ||
      // typeof search[k] === 'boolean' && search[k] ? a[k] : !a[k] // for filtering true and false
      (typeof search[k] === 'boolean' && search[k] && a[k]) || // for only filtering when true
      (typeof search[k] === 'boolean' && !search[k] && true),
  );

export const selectAttributeFilteredPointIds = createSelector(
  selectApiPoints, // this should be APIpoints because we don't want to filter new point before it can be given the attributes it needs
  (state) => state.filters.pointAttributes,
  (apiPoints, filters) => {
    // data = [{ id: "123", color: "Red", model: "Tesla" }, { id: "124", color: "Black", model: "Honda" }, { id: "125", color: "Red", model: "Audi" }, { id: "126", color: "Blue", model: "Tesla" }],
    // filters = { color: ['Red', 'Blue'], model: 'Tesla' };
    if (!filters || filters.length == 0) {
      return Object.keys(apiPoints);
    }

    return (
      Object.keys(apiPoints)
        .map((pointId) => apiPoints[pointId])
        .filter(useConditions(filters))
        // string because object.keys returns strings so when doing .includes() in selectFilteredPoints, everything needs to be the same type
        .map((point) => String(point.id))
    );
  },
);

// this is probably a very crappy way to set up edge filtering, just need something quick and only want to filter edges based on TSP right now
// ideally i think the selectFilteredPoints selector would just be reused somehow
// also ideally, we should make the filter state have edgeFilters, pointFilters, nodeFilters, etc.
export const selectFilteredEdgePoints = createSelector(
  selectApiPoints, // this should be APIpoints because we don't want to filter new point before it can be given the attributes it needs
  (state) => state.filters.pointAttributes?.tsp_name,
  /**
   *
   * @param {Object<string, import('../services/edgeApi').Point>} apiPoints
   * @param {string[]} tsp_names
   * @returns {Object<string, import('../services/edgeApi').Point>}
   */
  (apiPoints, tsp_names) => {
    // if there is no tsp_name filter, return all points
    if (!tsp_names || tsp_names.length === 0) {
      return apiPoints;
    }

    return Object.keys(apiPoints)
      .map((pointId) => apiPoints[pointId])
      .filter(
        (point) =>
          tsp_names.includes(point.tsp_name) ||
          tsp_names.includes(point.tsp_short_name),
      )
      .reduce(
        /**
         *
         * @param {Object<string, import('../services/edgeApi').Point>} obj
         * @param {import('../services/edgeApi').Point} point
         * @returns {Object<string, import('../services/edgeApi').Point>}
         */
        (obj, point) => {
          obj[point.id] = point;
          return obj;
        },
        {},
      );
  },
);

export const selectFilteredEdgeNodes = createSelector(
  selectApiNodes,
  selectFilteredEdgePoints,
  selectLocalNode,
  /**
   *
   * @param {Object<string, import('../services/edgeApi').Node>} apiNodes
   * @param {Object<string, import('../services/edgeApi').Point>} filteredPoints
   * @param {Object<string, import('../services/edgeApi').Node>} localNode
   * @returns {Object<string, import('../services/edgeApi').Node>}
   */
  (apiNodes, filteredPoints, localNode) => {
    // if(isFiltered){
    const filteredNodes = {};
    Object.keys(filteredPoints).map((pointId) => {
      const nodeId = filteredPoints[pointId].node;
      // return nodes[nodeId]
      if (nodeId) filteredNodes[nodeId] = apiNodes[nodeId];
    });
    // don't want to filter out the new node becuase it doesn't have a point yet.
    return { ...filteredNodes, ...localNode };
  },
);

export const selectCustomFilteredPointIds = createSelector(
  selectApiPoints,
  selectApiNodes,
  selectAttributeFilteredPointIds,
  selectInterconnects,
  (state) => state.filters.custom,
  (apiPoints, apiNodes, filteredPointIds, interconnects, customFilters) => {
    // isInterconnect
    const isInterconnect = Object.keys(interconnects)
      .map((interconnectId) => interconnects[interconnectId])
      .flatMap((interconnect) => [
        String(interconnect.start_point),
        String(interconnect.end_point),
      ]);

    // should probably union or intersect the result of all the custom filters instead of just returning the first result
    if (customFilters?.onlyKnownInterconnects) {
      // if(false){
      // console.log("interconnect points: " + JSON.stringify(isInterconnect))
      return isInterconnect;
    }

    // shouldBeInterconnect (but is not)
    // based on the TSPs that we have
    if (customFilters?.onlyPotentialInterconnects) {
      // get points that interconnect to tsp ferc cids that we have as primary tsp ferc cid in points
      // our tsp_ferc_cids
      // const apiPointArray = Object.keys(apiPoints).map(pointId => apiPoints[pointId])
      // console.log("filteredPointIDs = " + filteredPointIds)
      const apiPointArray = filteredPointIds.map(
        (pointId) => apiPoints[pointId],
      );
      const tspFercCids = apiPointArray
        .map((point) => point.tsp_ferc_cid)
        // this filters out null
        .filter((cid) => cid);
      const uniqueFercCids = [...new Set(tspFercCids)];
      // console.log(uniqueFercCids)
      const potentialInterconnect = apiPointArray
        .filter((point) => uniqueFercCids.includes(point.up_dn_ferc_cid))
        // remove points where the up_dn_ferc_cid matches the current tsp_ferc_cid
        .filter((point) => point.up_dn_ferc_cid != point.tsp_ferc_cid)
        .map((point) => String(point.id));

      // remove ones that are already interconnects
      return potentialInterconnect
        .filter((id) => !isInterconnect.includes(id))
        .map((id) => String(id));

      // could also do this on already filtered points so we drop the ones that connect to filtered out tsps - user can also select "connects to" to handle that.
      // TODO: "connects to" should use actual TSP objects instead of just raw tsp names
    }

    // we could also allow filters of all zones, which might be something that the traders would want anyway
    // also seems like this doesn't need to be custom but just a negated version of a boolean filter
    if (customFilters?.missingZone) {
      return Object.keys(apiPoints).filter(
        (pointId) => !apiPoints[pointId].receipt_zone,
      );
    }

    if (customFilters?.noEdges) {
      // if(true){
      return (
        Object.keys(apiNodes)
          .map((nodeId) => apiNodes[nodeId])
          .filter(
            (node) =>
              node.starts_edges.length == 0 && node.ends_edges.length == 0,
          )
          // get all the points associated with those nodes, filter other points so these nodes keep showing

          .flatMap((node) => String(node.points))
      );
    }

    // otherwise return all the point ids
    return Object.keys(apiPoints);
  },
);

// is it best to have one source of points with a flag that says "filters points" and "filters edges"
// or to have a bunch of arrays that are the results of different filters, and then do the intersection of all those arrays?

export const selectFilteredPoints = createSelector(
  selectApiPoints,
  selectAttributeFilteredPointIds,
  selectCustomFilteredPointIds,
  /**
   *
   * @param {{[key: string]: import('../services/edgeApi').Point}} apiPoints
   * @param {string[]} attributePointIds
   * @param {string[]} customPointIds
   * @returns {{[key: string]: import('../services/edgeApi').Point}}
   */
  (apiPoints, attributePointIds, customPointIds) => {
    // console.log(customPointIds)
    const intersection = attributePointIds.filter((attributePointId) =>
      customPointIds.includes(attributePointId),
    );
    return intersection.reduce((obj, pointId) => {
      obj[pointId] = apiPoints[pointId];
      return obj;
    }, {});
  },
);

export const selectFilteredNodes = createSelector(
  selectApiNodes,
  selectFilteredPoints,
  selectLocalNode,
  (state) => state.filters,
  /**
   *
   * @param {{[key: string]: import('../services/edgeApi').Node}} apiNodes
   * @param {{[key: string]: import('../services/edgeApi').Point}} filteredPoints
   * @param {{[key: string]: import('../services/edgeApi').Node}} localNode
   * @param {*} filters
   * @returns {{[key: string]: import('../services/edgeApi').Node}}
   */
  (apiNodes, filteredPoints, localNode, filters) => {
    // if(isFiltered){
    // if filters are empty, don't filter anything (return all nodes).  Otherwise it filters out nodes without points
    // BUG: this doesn't work quite right because once an individual filter is set and then removed, the filters object is not empty, even though there aren't really any filters.  Works if you click the remove filters button though

    if (Object.keys(filters).length === 0) {
      return { ...apiNodes, ...localNode };
    }

    const filteredNodes = {};
    Object.keys(filteredPoints).map((pointId) => {
      const nodeId = filteredPoints[pointId].node;
      // return nodes[nodeId]
      if (nodeId) filteredNodes[nodeId] = apiNodes[nodeId];
    });
    // don't want to filter out the new node becuase it doesn't have a point yet.
    return { ...filteredNodes, ...localNode };
    // this is temporary:
    // return {...apiNodes, ...localNode}
  },
);

// export const selectLocalPoint = createSelector(
//  state => state.selectedItems['localPoint or editPoint or what?'][0],
//  (pointId,) =>{

//  }
// )

// maybe this is more important to do for localNode since the point gets associated to the node via the node.points attribute
// or maybe we do it for both localnode and localpoint
// we are already saving localNode and localPoint stuff to state, could we just change it a little bit?
// for instance grab localNode, add 'matchingPoint' selector to its point array?

export const selectLocalEdge = createSelector(
  (state) => state.selectedItems.edge[0],
  (state) => state.selectedItems.edgeStartNode[0],
  (state) => state.selectedItems.edgeEndNode[0],
  selectAllNodes,
  (edgeId, startNodeId, endNodeId, allNodes) => {
    const startNode = allNodes[startNodeId];
    const endNode = allNodes[endNodeId];
    const positions =
      startNode && endNode
        ? [
            [startNode.lat, startNode.lng],
            [endNode.lat, endNode.lng],
          ]
        : null;
    // if there's no id selected yet, start with a new edge
    edgeId = edgeId || 'new';
    let edge;
    if ((startNodeId && endNodeId) || edgeId === 'new') {
      edge = {
        [edgeId]: {
          id: edgeId,
          start_node: startNodeId,
          end_node: endNodeId,
          positions,
        },
      };
    } else {
      // if edgeStartNode and edgeEndNode aren't set (which happens when edge starts to be edited), just return the api edge
      // in selectAllEdges the apiEdge will take precendence
      edge = null;
    }
    // edge = {[edgeId]: {id:edgeId, start: startNodeId, end: endNodeId, positions: positions}}

    return edge;
  },
);

export const selectAllEdges = createSelector(
  selectApiEdges,
  selectLocalEdge,
  // this one will be a little different because it needs to derive the 'local edge' from the selected start and end node, if they exist
  // (state) => state.plotter.localEdges,
  /**
   *
   * @param {{[key: string]: import('../services/edgeApi').Edge}} apiEdges
   * @param {{[key: string]: import('../services/edgeApi').Edge}} localEdge
   */
  (apiEdges, localEdge) =>
    // console.log('LOCAL EDGE ' + JSON.stringify(localEdge))
    ({ ...apiEdges, ...localEdge }),
);

export const selectFilteredEdges = createSelector(
  selectApiEdges,
  selectLocalEdge,
  getFilteredTsps,
  /**
   *
   * @param {{[key: string]: import('../services/edgeApi').Edge}} apiEdges
   * @param {{[key: string]: import('../services/edgeApi').Edge}} localEdge
   * @param {string[]} filteredTsps
   */
  (apiEdges, localEdge, filteredTsps) => {
    if (!filteredTsps || filteredTsps.length === 0)
      return { ...apiEdges, ...localEdge };
    /**
     * @type {{[key: string]: import('../services/edgeApi').Edge}}
     */
    const filteredEdges = Object.keys(apiEdges).reduce((acc, edgeId) => {
      const edge = apiEdges[edgeId];
      if (
        filteredTsps.includes(edge.tsp_short_name) ||
        filteredTsps.includes(edge.tsp_name)
      ) {
        acc[edgeId] = edge;
      }
      return acc;
    }, {});

    return { ...filteredEdges, ...localEdge };
    // return filteredEdges
  },
);

export const selectFilteredTsps = createSelector(
  getFilteredTsps,
  /**
   *
   * @param {string[] | undefined} tsps
   * @returns {string[]}
   */
  (tsps) => tsps ?? [],
);
