import React, { useEffect, useMemo, useState } from 'react';

/**
 * Documentation for this component can be found at
 * https://pathpoint-labs.atlassian.net/l/cp/anKkrSL0
 */

/**
 * @template {Object} T
 * @typedef {{
 *  items: T[],
 *  renderItem: (item: T, index: number, style: import('react').CSSProperties) => React.ReactNode,
 *  height: number,
 *  itemHeight: number,
 *  parentRef: React.RefObject<HTMLElement>,
 *  batchOffset: number,
 *  visible: boolean,
 *  fillerElement?: (style: import('react').CSSProperties) => React.ReactNode,
 * }} VirtualizedListProps<T>
 */

const getElementsInViewport = ({ height, itemHeight, batchOffset }) =>
  Math.ceil(height / itemHeight) + batchOffset * 2;

const geteStartElementIndex = ({ scrollTop, itemHeight, batchOffset }) =>
  Math.max(Math.floor(scrollTop / itemHeight) - batchOffset, 0);

/**
 * @template {Object} T
 * @param {VirtualizedListProps<T>} props
 * @returns {React.FC<VirtualizedListProps<T>>}
 * @see https://pathpoint-labs.atlassian.net/l/cp/anKkrSL0
 */
export const VirtualizedList = ({
  items,
  renderItem,
  height,
  itemHeight,
  parentRef,
  visible,
  batchOffset = 0,
  fillerElement = (style) => <div style={style} />,
}) => {
  const [scrollTop, setScrollTop] = useState(0);
  const [elementsInViewport, setElementsInViewport] = useState(
    getElementsInViewport({ height, itemHeight, batchOffset }),
  );
  const [startItemIndex, setStartItemIndex] = useState(
    geteStartElementIndex({ scrollTop, itemHeight, batchOffset }),
  );

  const getItemPosition = (index) => {
    return index * itemHeight;
  };

  useEffect(() => {
    const current = parentRef.current;
    if (!current || !visible) return;

    current.scrollTop = scrollTop;

    const listener = (e) => {
      setScrollTop(e.target.scrollTop);
      setStartItemIndex(
        geteStartElementIndex({
          scrollTop: e.target.scrollTop,
          itemHeight,
          batchOffset,
        }),
      );
    };

    parentRef.current.addEventListener('scroll', listener);

    return () => {
      current.removeEventListener('scroll', listener);
    };
  }, [parentRef.current, visible]);

  useEffect(() => {
    setElementsInViewport(
      getElementsInViewport({ height, itemHeight, batchOffset }),
    );
    setStartItemIndex(
      geteStartElementIndex({ scrollTop, itemHeight, batchOffset }),
    );
  }, [height, itemHeight]);

  const elements = useMemo(() => {
    if (!visible) return [];
    const elementsToRender = items.slice(
      startItemIndex,
      startItemIndex + elementsInViewport,
    );
    return elementsToRender.map((item, index) =>
      renderItem(item, index + startItemIndex, {
        position: 'absolute',
        top: getItemPosition(index + startItemIndex),
        width: '100%',
      }),
    );
  }, [items, visible, startItemIndex, elementsInViewport, renderItem]);

  return (
    <>
      {elements}
      {fillerElement({ height: items.length * itemHeight })}
    </>
  );
};
