import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { WebGLLayer } from './webglLayer';
import { useSelector } from 'react-redux';
import {
  selectAllEdges,
  selectFilteredEdges,
} from '../plotter/plotterSelectors';
import { Vector as VectorSource } from 'ol/source.js';
import GeoJSON from 'ol/format/GeoJSON.js';
import { generateColor } from '../utils/colors';
import { mapContext } from './EdgeMap';
import { MapTooltip } from './MapTooltip';
import { MapPopup } from './MapPopup';
import { EdgePopup } from '../edges/EdgePopup';
import { selectEditState, selectScenario } from '../scenarios/scenarioSlice';
import { edgeToGeoJson } from '../utils/geoJsonUtils';
import { EdgeTooltip } from '../edges/EdgeTooltip';
import { useTheme } from '../helpers/hooks';

const getEdgeStyle = () => [
  {
    filter: ['<=', ['get', 'head'], 0],
    'stroke-width': 4,
    'stroke-color': ['get', 'color'],
    'stroke-line-cap': 'round',
    'stroke-line-join': 'round',
  },
  {
    filter: ['>', ['get', 'head'], 0],
    'shape-points': 3,
    'shape-radius': 10,
    'shape-fill-color': ['get', 'color'],
    'shape-stroke-color': ['get', 'color'],
    'shape-stroke-width': 4,
    'shape-rotate-with-view': false,
    'shape-displacement': [0, 0],
    'shape-opacity': 1,
    'shape-angle': ['get', 'angle'],
  },
];

/**
 *
 * @type {import('../utils/geoJsonUtils').PropertyExtractor}
 */
const extractGeoJsonProps = (edge, _arrowHead, theme) => {
  return {
    key: `edge-${edge.id}`,
    id: edge.id,
    color:
      theme === 'light'
        ? generateColor(edge.tsp_id || 0)
        : generateColor(edge.tsp_id || 0, 50, 40),
  };
};

export const MapEdges = ({ zIndex = 1 }) => {
  const {
    map,
    registerHoverListener,
    unregisterHoverListener,
    registerClickListener,
    unregisterClickListener,
  } = useContext(mapContext);
  const edges = useSelector(selectFilteredEdges);
  const allEdges = useSelector(selectAllEdges);
  const showEdgeDirections = useSelector(
    (state) => state.plotter.showEdgeDirections,
  );
  const isEditing = useSelector(selectEditState);
  const scenario = useSelector(selectScenario);

  /** @type {import('./MapPopup').MapPopupRef} */
  const popupRef = useRef(null);

  /** @type {[import('../services/edgeApi').Edge, React.Dispatch<React.SetStateAction<import('../services/edgeApi').Edge>>]} */
  const [tooltipEdge, setTooltipEdge] = useState(null);

  /** @type {[import('../services/edgeApi').Edge, React.Dispatch<React.SetStateAction<import('../services/edgeApi').Edge>>]} */
  const [popupEdge, setPopupEdge] = useState(null);

  const [theme] = useTheme();

  const isScenarioPage = location.pathname.match(/\/scenarios\/\d+$/);
  const isMarketConditionsPage = location.pathname === '/';

  const flowsOnEdges = useMemo(() => {
    if (!isScenarioPage || isEditing) {
      return {};
    }
    const flows = scenario.operations?.reduce((agg, op) => {
      const pathEdgeNodes = op.path_node_ids.slice(0, -1).map((id, i) => {
        return [Number(id), Number(op.path_node_ids[i + 1])];
      });
      pathEdgeNodes.forEach(([startNode, endNode]) => {
        const edge = Object.values(allEdges).find(
          (e) => e.start_node === startNode && e.end_node === endNode,
        );
        if (!edge) return;
        if (!agg[edge.id]) {
          agg[edge.id] = [];
        }
        agg[edge.id].push(op);
      });
      return agg;
    }, {});
    return flows;
  }, [isScenarioPage, allEdges, scenario.operations, isEditing]);

  /** @type {import('./EdgeMap').MapEventListener} */
  const hoverListener = useCallback(
    (feature) => {
      if (feature?.getProperties()?.key === `edge-${tooltipEdge?.id}`) return;
      if (!feature?.getProperties()?.key?.startsWith('edge-')) {
        setTooltipEdge(null);
        return;
      }
      const edgeId = feature?.getProperties()?.id;
      const edge = edges?.[edgeId];
      setTooltipEdge(edge);
    },
    [edges],
  );

  const closePopup = useCallback(() => {
    setPopupEdge(null);
    popupRef.current.hide();
  }, [popupRef, setPopupEdge]);

  /** @type {import('./EdgeMap').MapEventListener} */
  const clickListener = useCallback(
    (feature, coordinate) => {
      if (feature?.getProperties()?.key === `edge-${popupEdge?.id}`) return;

      const edgeId =
        feature && feature.getProperties()?.key?.startsWith('edge-')
          ? feature.getProperties()?.id
          : null;
      const edge = edges?.[edgeId];
      if (edge) popupRef.current.show(coordinate);
      else popupRef.current.hide();
      setPopupEdge(edge);
    },
    [edges],
  );

  const edgesLayer = useMemo(() => {
    const features = Object.values(edges)
      .filter((edge) => edge?.positions?.length > 1)
      .flatMap((edge) =>
        edgeToGeoJson(edge, extractGeoJsonProps, showEdgeDirections, {}, theme),
      );
    const source = new VectorSource({
      features: new GeoJSON().readFeatures({
        type: 'FeatureCollection',
        features,
      }),
      format: new GeoJSON(),
    });

    return new WebGLLayer({
      source,
      style: getEdgeStyle(),
      zIndex,
    });
  }, [edges, showEdgeDirections, theme]);

  useEffect(() => {
    if (!map || !edgesLayer) return;
    registerHoverListener(hoverListener);
    return () => {
      unregisterHoverListener(hoverListener);
    };
  }, [map, hoverListener]);

  useEffect(() => {
    if (!map || !edgesLayer) return;
    registerClickListener(clickListener);
    return () => {
      unregisterClickListener(clickListener);
    };
  }, [map, clickListener]);

  useEffect(() => {
    if (!map || !edgesLayer) return;
    map.addLayer(edgesLayer);
    return () => {
      map.removeLayer(edgesLayer);
      edgesLayer.dispose();
    };
  }, [map, edgesLayer]);

  return (
    <>
      <MapTooltip key="edges-tooltip" visible={Boolean(tooltipEdge)}>
        <EdgeTooltip
          edge={tooltipEdge}
          showScenarioInfo={isScenarioPage && !isEditing}
          showCapacities={isMarketConditionsPage}
          operations={flowsOnEdges[tooltipEdge?.id] ?? []}
        />
      </MapTooltip>
      <MapPopup key="edges-popup" ref={popupRef} onClose={closePopup} autoPan>
        <EdgePopup
          edge={popupEdge}
          operations={flowsOnEdges[popupEdge?.id] ?? []}
        />
      </MapPopup>
    </>
  );
};
