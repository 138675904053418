import React, { useContext, useEffect, useRef } from 'react';
import { mapContext } from './EdgeMap';

/**
 * @typedef {{
 *  sticky?: boolean,
 *  position?: { x: number, y: number },
 *  visible?: boolean,
 *  children: React.ReactNode[]
 *  style?: React.CSSProperties
 *  className?: string
 *  maxWidth?: number
 * }} MapTooltipProps
 */

/**
 *
 * @param {MapTooltipProps} props
 * @returns {React.FC<MapTooltipProps>}
 */
export const MapTooltip = ({
  sticky = true,
  position,
  visible = false,
  children,
  style,
  className,
}) => {
  /** @type {React.MutableRefObject<HTMLDivElement | null>} */
  const tooltipRef = useRef(null);
  const { map } = useContext(mapContext);

  useEffect(() => {
    const posPadding = { x: 16, y: 45 };
    if (sticky) {
      const listener = (e) => {
        if (!tooltipRef.current) return;
        const mapContainer = map.getTargetElement();
        // check if mouse is inside the area of the map container
        if (
          e.clientX < mapContainer.offsetLeft ||
          e.clientX > mapContainer.offsetLeft + mapContainer.offsetWidth + 20 ||
          e.clientY < mapContainer.offsetTop ||
          e.clientY > mapContainer.offsetTop + mapContainer.offsetHeight + 20
        ) {
          return;
        }
        const mousePos = { x: e.clientX, y: e.clientY };
        tooltipRef.current.style.left = `${mousePos.x + posPadding.x}px`;
        tooltipRef.current.style.right = 'auto';

        // If the mouse is too close to the right border of the screen, move the tooltip to the left
        if (
          mousePos.x + tooltipRef.current.offsetWidth >
          window.innerWidth - 20
        ) {
          tooltipRef.current.style.right = `${
            window.innerWidth - (mousePos.x - posPadding.x)
          }px`;
          tooltipRef.current.style.left = 'auto';
          tooltipRef.current.style.flexDirection = 'row-reverse';
        }

        tooltipRef.current.style.top = `${Math.min(
          window.innerHeight - tooltipRef.current.offsetHeight,
          mousePos.y - posPadding.y,
        )}px`;
      };

      window.addEventListener('mousemove', listener);
      return () => {
        window.removeEventListener('mousemove', listener);
      };
    } else {
      if (!position) {
        console.error('MapTooltip: position is required when sticky is false');
      }
      if (!tooltipRef.current) return;
      tooltipRef.current.style.left = `${position.x + posPadding.x}px`;
      tooltipRef.current.style.top = `${position.y - posPadding.y}px`;
    }
  }, [tooltipRef, map, sticky]);

  return (
    <div
      ref={tooltipRef}
      className={className}
      style={{
        display: visible ? 'flex' : 'none',
        position: 'absolute',
        zIndex: 1000,
        overflow: 'hidden',
        ...style,
      }}
    >
      {children}
    </div>
  );
};
