// import DataTable from "react-data-table-component"
import { memo, useMemo, useState } from 'react';
import {
  ChartIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  ZoomInIcon,
} from '@iconicicons/react';
import { useSelector } from 'react-redux';
import { usePanMap } from '../plotter/usePanMap';
import * as plotterSelectors from '../plotter/plotterSelectors';
import { ReusableModal } from '../modals/ReusableModal';
import { PriceComponentManager } from './PriceComponentManager';
import { PriceComponentHistory } from './PriceComponentHistory';
import { deepEqual } from '../utils/objectUtils';
import { useDebouncedState } from '../helpers/hooks';

/**
 * @typedef {{
 *  id: number,
 *  points: number[],
 *  description: string,
 *  record_to_use?: {
 *    description: string,
 *    values: {
 *      high: number,
 *      low: number,
 *      close: number,
 *      volume: number
 *    }
 *  },
 *  nodes: number[]
 * }} PriceComponent
 */

/**
 *
 * @param {{
 *  priceComponents: {[key: string]: PriceComponent},
 *  onFilterChange: (filter: unknown) => void,
 *  priceSource: import('../scenarios/scenarioSlice').PriceSource,
 * }} props
 * @returns
 */
const _PriceComponentTable = ({
  priceComponents,
  priceSource,
  onFilterChange,
}) => {
  const panMap = usePanMap();

  // TODO: should we be relying on nodes being here already?  Or just send the lat/lng from backend
  const allNodes = useSelector(plotterSelectors.selectAllNodes);
  const allPoints = useSelector(plotterSelectors.selectAllPoints);
  const [componentHistoryId, setComponentHistoryId] = useState(false);
  const [componentId, setComponentId] = useState(false);
  const [sortKey, setSortKey] = useState('id');
  const [sortOrder, setSortOrder] = useState('desc');
  const [minLocations, setMinLocations] = useState(null);
  const [maxLocations, setMaxLocations] = useState(null);
  const [filter, setFilter] = useDebouncedState('', 500);

  const [component, setComponent] = useState('');

  const data = useMemo(() => {
    if (!priceComponents) {
      return null;
    }

    const filteredPriceComponents = Object.values(priceComponents).reduce(
      (acc, price) => {
        const condition =
          (filter?.length < 1 ||
            [
              price.description,
              price.record_to_use?.description,
              ...price.points.map((pointId) => allPoints[pointId]?.loc_name),
            ].some((val) =>
              val?.toLowerCase().includes(filter.toLowerCase()),
            )) &&
          (minLocations === null || price.points.length >= minLocations) &&
          (maxLocations === null || price.points.length <= maxLocations);

        if (condition) {
          acc[price.id] = price;
        }
        return acc;
      },
      {},
    );

    onFilterChange(filteredPriceComponents);

    return Object.values(filteredPriceComponents).map((price) => {
      if (['PLATTS_DAILY', 'ICE_DAILY', 'ICE_FUTURE'].includes(priceSource)) {
        return {
          id: price.id,
          nodes: price.nodes,
          market: price.description,
          description: price.record_to_use.description,
          high: price.record_to_use.values.high,
          low: price.record_to_use.values.low,
          close: price.record_to_use.values.close,
          volume: price.record_to_use.values.volume,
          locations: price.points.length,
        };
      } else if (['ICE_LIVE'].includes(priceSource)) {
        return {
          id: price.id,
          nodes: price.nodes,
          market: price.description,
          description: price.record_to_use.description,
          lastPrice: price.record_to_use.values.lst_prc,
          bestLiftPrice: price.record_to_use.values.bst_lift_prc,
          bestHitPrice: price.record_to_use.values.bst_hit_prc,
          locations: price.points.length,
        };
      }
    });
  }, [priceComponents, priceSource, minLocations, maxLocations, filter]);

  const sortedData = useMemo(() => {
    return data?.sort((a, b) => {
      let sortValue = 0;
      if (typeof a[sortKey] !== typeof b[sortKey]) sortValue = 0;
      if (typeof a[sortKey] === 'string') {
        sortValue = a[sortKey].localeCompare(b[sortKey]);
      } else if (
        typeof a[sortKey] === 'number' ||
        typeof a[sortKey] === 'boolean'
      ) {
        sortValue = a[sortKey] - b[sortKey];
      }

      return sortOrder === 'asc' ? sortValue : -sortValue;
    });
  }, [data, sortKey, sortOrder]);

  const setSorting = (key) => {
    if (sortKey === key) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortKey(key);
      setSortOrder('asc');
    }
  };

  if (!sortedData) {
    return null;
  }

  return (
    <>
      <table className="table table-responsive">
        <thead>
          <tr>
            <th>Go To</th>
            <th>History</th>
            <th>
              <span role="button" onClick={() => setSorting('market')}>
                Market
                {sortKey === 'market' &&
                  (sortOrder === 'asc' ? (
                    <ChevronUpIcon />
                  ) : (
                    <ChevronDownIcon />
                  ))}
              </span>
              <input
                className="form-control w-100"
                type="text"
                defaultValue={filter}
                onChange={(e) => setFilter(e.target.value ?? '')}
                placeholder="Search Market"
              />
            </th>
            <th role="button" onClick={() => setSorting('description')}>
              Description
              {sortKey === 'description' &&
                (sortOrder === 'asc' ? <ChevronUpIcon /> : <ChevronDownIcon />)}
            </th>
            {['PLATTS_DAILY', 'ICE_DAILY', 'ICE_FUTURE'].includes(
              priceSource,
            ) && (
              <>
                <th role="button" onClick={() => setSorting('high')}>
                  High
                  {sortKey === 'high' &&
                    (sortOrder === 'asc' ? (
                      <ChevronUpIcon />
                    ) : (
                      <ChevronDownIcon />
                    ))}
                </th>
                <th role="button" onClick={() => setSorting('low')}>
                  Low
                  {sortKey === 'low' &&
                    (sortOrder === 'asc' ? (
                      <ChevronUpIcon />
                    ) : (
                      <ChevronDownIcon />
                    ))}
                </th>
                <th role="button" onClick={() => setSorting('close')}>
                  Close
                  {sortKey === 'close' &&
                    (sortOrder === 'asc' ? (
                      <ChevronUpIcon />
                    ) : (
                      <ChevronDownIcon />
                    ))}
                </th>
                <th role="button" onClick={() => setSorting('volume')}>
                  Volume
                  {sortKey === 'volume' &&
                    (sortOrder === 'asc' ? (
                      <ChevronUpIcon />
                    ) : (
                      <ChevronDownIcon />
                    ))}
                </th>
              </>
            )}
            {['ICE_LIVE'].includes(priceSource) && (
              <>
                <th role="button" onClick={() => setSorting('lastPrice')}>
                  Last Price
                  {sortKey === 'lastPrice' &&
                    (sortOrder === 'asc' ? (
                      <ChevronUpIcon />
                    ) : (
                      <ChevronDownIcon />
                    ))}
                </th>
                <th role="button" onClick={() => setSorting('bestLiftPrice')}>
                  Best Lift Price
                  {sortKey === 'bestLiftPrice' &&
                    (sortOrder === 'asc' ? (
                      <ChevronUpIcon />
                    ) : (
                      <ChevronDownIcon />
                    ))}
                </th>
                <th role="button" onClick={() => setSorting('bestHitPrice')}>
                  Best Hit Price
                  {sortKey === 'bestHitPrice' &&
                    (sortOrder === 'asc' ? (
                      <ChevronUpIcon />
                    ) : (
                      <ChevronDownIcon />
                    ))}
                </th>
              </>
            )}
            <th>
              <span role="button" onClick={() => setSorting('locations')}>
                Locations
                {sortKey === 'locations' &&
                  (sortOrder === 'asc' ? (
                    <ChevronUpIcon />
                  ) : (
                    <ChevronDownIcon />
                  ))}
              </span>
              <div className="input-group" style={{ maxWidth: 200 }}>
                <input
                  className="form-control"
                  type="number"
                  min={0}
                  defaultValue={minLocations}
                  onChange={(e) =>
                    setMinLocations(
                      Number.isNaN(e.target.valueAsNumber)
                        ? null
                        : e.target.valueAsNumber,
                    )
                  }
                  placeholder="Min"
                />
                <input
                  className="form-control"
                  type="number"
                  min={0}
                  defaultValue={maxLocations}
                  onChange={(e) =>
                    setMaxLocations(
                      Number.isNaN(e.target.valueAsNumber)
                        ? null
                        : e.target.valueAsNumber,
                    )
                  }
                  placeholder="Max"
                />
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {sortedData.map((component) => {
            const nodeInfo = allNodes[component.nodes[0]];
            return (
              <tr key={component.id}>
                <td>
                  <button
                    className="btn btn-light w-100"
                    disabled={!nodeInfo}
                    onClick={() => {
                      if (nodeInfo) panMap(nodeInfo, 11);
                    }}
                  >
                    <ZoomInIcon />
                  </button>
                </td>
                <td>
                  <button
                    className="btn btn-light w-100"
                    onClick={() => setComponentHistoryId(component.id)}
                  >
                    <ChartIcon />
                  </button>
                </td>
                <th>{component.market}</th>
                <td>{component.description}</td>
                {['PLATTS_DAILY', 'ICE_DAILY', 'ICE_FUTURE'].includes(
                  priceSource,
                ) && (
                  <>
                    <td>{component.high}</td>
                    <td>{component.low}</td>
                    <td>{component.close}</td>
                    <td>{component.volume}</td>
                  </>
                )}
                {['ICE_LIVE'].includes(priceSource) && (
                  <>
                    <td>{component.lastPrice}</td>
                    <td>{component.bestLiftPrice}</td>
                    <td>{component.bestHitPrice}</td>
                  </>
                )}
                <td>
                  <button
                    className="btn btn-primary"
                    onClick={() => setComponentId(component.id)}
                  >
                    {component.locations}
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>

      <ReusableModal
        onClose={() => setComponentId(false)}
        isOpen={!!componentId}
        header={component?.description}
      >
        {componentId && (
          <PriceComponentManager
            componentId={componentId}
            setComponent={setComponent}
          />
        )}
      </ReusableModal>

      {componentHistoryId && (
        <PriceComponentHistory
          componentId={componentHistoryId}
          priceSource={priceSource}
          setComponentId={setComponentHistoryId}
        />
      )}
    </>
  );
};

export const PriceComponentTable = memo(
  _PriceComponentTable,
  (prevProps, nextProps) => {
    return deepEqual(prevProps.priceComponents, nextProps.priceComponents);
  },
);
