import { PopupSegmentConstraints } from '../segmentConstraints/PopupSegmentConstraints';
import { EdgeFlowTable } from './EdgeFlow';

/**
 *
 * @param {{
 *  edge: import('../services/edgeApi').Edge,
 *  operations: import('../scenarios/scenarioSlice').Operation[]
 * }} props
 * @returns
 */
export function EdgePopup({ edge, operations }) {
  if (!edge) return null;
  return (
    <>
      <small>#{edge.id}</small>
      <p className="my-0 fw-bold">{edge.tsp_name}</p>
      <p className="my-0">
        {edge.zone} {edge.seg_nbr ? `- Seg: ${edge.seg_nbr}` : null}
      </p>
      <EdgeFlowTable
        operations={operations}
        className="table table-bordered table-sm"
      />
      <PopupSegmentConstraints edgeId={edge.id} />
    </>
  );
}

// could show simple stuff on hover, more complex stuff on click
// need to activate when a point is selected? Can also help with finding the point
// add / view market data when it exists
// at what point does this all start to bog down?
