import { useSelector } from 'react-redux';
import { ReduxPointSearch } from '../search/ReduxPointSearch';
import * as plotterSelectors from '../plotter/plotterSelectors';

/**
 *
 * @description A component that applies a style to an input or select element based on whether it is dirty or saved
 *
 * @param {React.HTMLProps<HTMLInputElement> & React.HTMLProps<HTMLSelectElement> & {
 *  dirty: boolean;
 *  saved: boolean;
 *  dirtyStyle: React.HTMLProps<HTMLInputElement>.style;
 *  savedStyle: React.HTMLProps<HTMLInputElement>.style;
 *  type: React.HTMLProps<HTMLInputElement>.type | 'select';
 * }} props
 */
export function EditFeedbackInput({
  dirty,
  saved,
  style,
  children,
  value,
  className,
  onChange,
  dirtyStyle = { backgroundColor: '#F9FCDC', color: 'black' },
  savedStyle = { backgroundColor: '#ABF7B1', color: 'black' },
  ...props
}) {
  if (props.type === 'select') {
    // const selectProps = {...props, type: undefined}
    return (
      <select
        {...props}
        value={value}
        onChange={onChange}
        className={className}
        style={{
          ...style,
          ...(saved ? savedStyle : {}),
          ...(dirty ? dirtyStyle : {}),
        }}
      >
        {children}
      </select>
    );
  }
  return (
    <input
      {...props}
      value={value}
      onChange={onChange}
      className={className}
      style={{
        ...style,
        ...(saved ? savedStyle : {}),
        ...(dirty ? dirtyStyle : {}),
      }}
    />
  );
}

/**
 *
 * @description A component that applies a style to a point input element based on whether it is dirty or saved
 *
 * @param {React.HTMLProps<HTMLInputElement> & React.HTMLProps<HTMLSelectElement> & {
 *  dirty: boolean;
 *  saved: boolean;
 *  dirtyStyle: React.HTMLProps<HTMLInputElement>.style;
 *  savedStyle: React.HTMLProps<HTMLInputElement>.style;
 *  type: React.HTMLProps<HTMLInputElement>.type | 'select';
 *  onChange: (pointId: string) => void;
 * }} props
 */
export const EditFeedbackPointInput = ({
  value: currentPoint,
  placeholder,
  dirty,
  saved,
  onChange,
  style,
  dirtyStyle = { backgroundColor: '#F9FCDC' },
  savedStyle = { backgroundColor: '#E7FFDE' },
}) => {
  return (
    <ReduxPointSearch
      style={{
        ...style,
        ...(saved ? savedStyle : {}),
        ...(dirty ? dirtyStyle : {}),
      }}
      className="form-control-sm"
      handler={(point) => onChange(point.id)}
      value={currentPoint}
      placeholder={currentPoint || placeholder || 'Point #'}
      buttonTitle="Select A Point"
    />
  );
};

/**
 *
 * @description A component that applies a style to a point input element based on whether it is dirty or saved
 *
 * @param {React.HTMLProps<HTMLInputElement> & React.HTMLProps<HTMLSelectElement> & {
 *  dirty: boolean;
 *  saved: boolean;
 *  dirtyStyle: React.HTMLProps<HTMLInputElement>.style;
 *  savedStyle: React.HTMLProps<HTMLInputElement>.style;
 *  type: React.HTMLProps<HTMLInputElement>.type | 'select';
 *  onChange: (pointId: string) => void;
 * }} props
 */
export const EditFeedbackSegmentInput = ({
  value,
  placeholder,
  dirty,
  saved,
  onChange,
  style,
  dirtyStyle = { backgroundColor: '#F9FCDC' },
  savedStyle = { backgroundColor: '#E7FFDE' },
}) => {
  const allPoints = useSelector(plotterSelectors.selectAllPoints);
  const point = allPoints[value] ?? null;
  const title = point ? `Flow from ${point.loc_name}` : null;

  return (
    <ReduxPointSearch
      style={{
        ...style,
        ...(saved ? savedStyle : {}),
        ...(dirty ? dirtyStyle : {}),
      }}
      className="form-control-sm"
      handler={(point) => onChange(point.id)}
      value={title}
      placeholder={placeholder || 'Segment #'}
      buttonTitle="Select a Segment"
    />
  );
};
